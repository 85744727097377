import React from 'react';
import '../css/Footer.css';
class Footer extends React.Component{
    render(){
        return (
            <footer className="homepage__footer--wrapper">
                <p className="homepage__footer--text">&copy; Deltalex Advocaten</p>
            </footer>
        )
    }
}

export default Footer;
