import React from "react";
//import { Map, Marker } from 'pigeon-maps';
import { maptiler } from 'pigeon-maps/providers'
import '../css/map.css';

class ContactMap extends React.Component {

    getProvider() {
        return maptiler(process.env.REACT_APP_MAP_KEY, 'streets');
    }

    /* render() {
        return (
            <Map
                provider={this.getProvider()}
                dprs={[1, 2]}
                defaultCenter={[50.85199, 3.34275]}
                defaultZoom={11}
                width={this.getDimensions().width}
                height={this.getDimensions().height}
            >
                <Marker

                />
            </Map>
        )
    }*/

    componentDidMount(){
        window.addEventListener('resize', () => {this.forceUpdate()})
    }

    render() {
        return (
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.8828634925085!2d3.338698777051327!3d50.85185335838051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c33d783b862b51%3A0x78e32081e25014a3!2sDeltalex%20Advocaten!5e0!3m2!1sen!2sbe!4v1688486117573!5m2!1sen!2sbe"
                width={this.getDimensions().width}
                height={this.getDimensions().height}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="contact__map">

            </iframe>
        )
    }

    getDimensions() {
        if (window.innerWidth > 950) {
            return {
                "width": window.innerWidth * .66 / 100 * 48,
                "height": "500px"
            }
        } else {
            return {
                "width": window.innerWidth,
                "height": window.innerHeight / 2.5
            }
        }
    }
}

export default ContactMap;
