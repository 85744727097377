import React from "react";
import '../css/homePage.css';
import HeaderBar from "../components/HeaderBar";
import bannerVideoUrlMp4 from "../assets/home/background_video.mp4";
import bannerVideoUrlWebm from "../assets/home/background_video.webm";
import introBackground from './../assets/home/intro__background.webp';
import Footer from "../components/Footer";

class HomePage extends React.Component {
    constructor(props){
        window.scroll({top: 0})
        document.title = 'Deltalex'
        super(props)
    }

    componentDidMount(){
        window.addEventListener('resize', () => {this.forceUpdate()})
    }

    render() {
        return (
            <div className="homepage__main--wrapper">
                <div className="homepage__header--wrapper">
                    <div className="homepage__header--bar"><HeaderBar CurrentPage="home" /></div>
                    <div className="homepage__video--wrapper">
                        <div className="homepage__video--overlay" />
                        <video autoPlay loop muted playsInline className="homepage__video">
                            <source src={bannerVideoUrlWebm}/>
                            <source src={bannerVideoUrlMp4} />
                        </video>
                        <p className="homepage__video--text">
                            Een gedreven verdediging van uw belangen
                        </p>
                    </div>
                </div>
                <div className="homepage__intro--wrapper">
                    <div className="homepage__intro--content">
                        <h2 className="homepage__intro--title">Welkom bij Deltalex!</h2>
                        <p className="homepage__intro--body">
                            Deltalex Advocaten is een dynamisch kantoor gelegen in het hart van Zuid-West-Vlaanderen.
                        </p><p className="homepage__intro--body">
                            Door de bundeling van de krachten staat voor U een veelzijdig, bekwaam en toegewijd team met tonnen aan ervaring en kennis.
                        </p><p className="homepage__intro--body">
                            De missie van het kantoor is om U op een betrouwbare, integere en gedreven wijze bij te staan.
                            Inderdaad, U met een hoofdletter, want wij ondernemen in Úw recht.  Uw belang staat centraal.
                            Een persoonlijke aanpak, laagdrempeligheid en transparantie staan daartoe garant, en vormen voor ons dan ook kernwaarden.
                        </p>
                        <p className="homepage__intro--body">
                            Dankzij een sterke interactie streven wij naar een oplossing op Uw maat. Dit gaat van proactief optreden door het verlenen van degelijke adviezen en opmaken van sluitende contracten,
                            over conflictbemiddeling met het oog op een aanvaardbare minnelijke oplossing tot krachtdadige rechtsbijstand bij procedures voor Hoven en Rechtbanken.
                            In om het even welke situatie staan wij met volle kracht en overtuiging naast U in een nauwe wederzijdse betrokkenheid.
                        </p>
                        <p className="homepage__intro--body">
                            We geloven dat deze elementen essentieel zijn om u effectief bij te staan in juridische kwesties.
                            Met een team van deskundige advocaten zijn we goed uitgerust om u te adviseren en te vertegenwoordigen in uiteenlopende juridische aangelegenheden.
                        </p><p className="homepage__intro--body">
                            Het gedistingeerd Deltalex-logo doet niet alleen denken aan onze toga, maar evenzeer aan een bol zeil. 
                            De wind in de zeilen symboliseert onze dynamiek en gedrevenheid om progressie te maken in de behandeling van Uw dossier. 
                        </p>
                        <p className="homepage__intro--body">
                            Ga gerust verder met Uw bezoek hier op onze site en aarzel vooral niet ons te contacteren ingeval van om het even welke vraag of issue van juridische aard. 
                        </p>
                    </div>
                    <div className="homepage__intro--overlay" />
                    <img src={introBackground} alt="intro background" className="homepage__intro--background" />
                </div>
                <Footer />
            </div>
        )
    }
}

export default HomePage;
