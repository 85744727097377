import React from 'react';

import '../css/headerBar.css';
import wideLogo from '../assets/logo__wide.png';
import narrowLogo from '../assets/logo__narrow.png';
import hamburger from '../assets/hamburger.svg';


import {Link} from "react-router-dom";

class HeaderBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: props.CurrentPage,
            mobileMenuExpanded: false,
            pageTitle: props.pageTitle
        };
    }

    render() {
        if (window.innerWidth <= 550)
            return this.renderMobileContent();
        else
            return this.renderDesktopContent();
    }

    componentDidMount(){
        window.addEventListener('resize', () => {this.forceUpdate()})
    }

    renderDesktopContent() {
        return (
            <div className="header__wrapper">
                <div className="header__content--wrapper">
                    <img src={wideLogo} alt="logo"
                         className="header__menu--logo"/>
                    <div className="header__menu--wrapper">
                        {this.getLinkList()}
                    </div>
                </div>
            </div>
        )
    }

    renderMobileContent() {
        return (
            <div className="header__wrapper">
                <div className="header__content--wrapper">
                    <img src={narrowLogo} alt="logo"
                         className="header__menu--logo"/>
                    <h1 className='header__menu--title'>{this.state.pageTitle}</h1>
                    <div className="header__menu--wrapper">
                        <img src={hamburger} alt="hamburger" className="header__menu--hamburger"
                             onClick={this.handleMenuToggle}/>
                    </div>

                </div>
                <div className="header__links--wrapper">
                    {this.getLinkList()}
                </div>
            </div>
        )
    }

    handleMenuToggle() {
        const translateStyle = "translateX(25rem)";
        const menu = document.querySelector(".header__links--wrapper");

        if(menu.style.transform === "" || menu.style.transform === translateStyle){
            menu.style.transform = "unset";
        } else if (menu.style.transform === "unset"){
            menu.style.transform = translateStyle
        }
    }

    getLinkList() {
        return ([
            <Link to="/" key="home"
                  className={"header__menu--link " + (this.state.page === "home" ? "selected" : "")}>Home</Link>,
            <Link to="/team" key="team"
                  className={"header__menu--link " + (this.state.page === "team" ? "selected" : "")}>Het Team</Link>,
            <Link to="/expertise" key="expertise"
                  className={"header__menu--link " + (this.state.page === "expertise" ? "selected" : "")}>Expertise & Socials</Link>,
            <Link to="/contact" key="contact"
                  className={"header__menu--link " + (this.state.page === "contact" ? "selected" : "")}>Contact</Link>
        ]);
    }
}

export default HeaderBar;
