import nicolasImg from "./nicolas.webp";
import eliImg from "./eli.webp";
import yvesImg from "./yves.webp";
import arianneImg from "./arianne.webp";
import stijnImg from "./stijn.webp";
import emmaImg from "./emma.webp";

const lawyers = [{
    id: 1,
    name: "Nicolas Duquesnoy",
    email: "nicolas@deltalex.be",
    image: <img className="lawyer__image" src={nicolasImg} alt="Nicolas Duquesnoy" key="nicolas" />,
    Description: <span className="lawyer__description">
        <p>
            Mter. Nicolas Duquesnoy studeerde rechten aan de universiteit van Brussel en behaalde zijn diploma als licentiaat in de rechten.
        </p><p>
            Hij startte in 1996 aan de Balie te Kortrijk.
        </p><p>
            Nicolas Duquesnoy heeft een ruime ervaring in incasso (invorderen onbetaalde facturen), handelsrecht, arbeidsrecht, aanneming, verkeersrecht, huurrecht, collectieve schuldenregelingen en faillissementen.
        </p><p>
            Sinds 2000 is hij opgenomen op de lijst van de faillissementscuratoren te Kortrijk.
        </p><p>
            Mter. Duquesnoy voltooide verder de opleiding als consulent in de sociale wetgeving.
        </p><p>
            Hij schoolt zich op frequente basis bij om mee te zijn en te blijven met de evoluerende regelgeving.
        </p><p>
            Door zijn jarenlange ervaring in diverse takken van het recht kan hij U kordaat en accuraat bijstaan. Hij is integer alsook direct aanspreekbaar, en draagt een persoonlijke, betrouwbare en transparante dienstverlening hoog in het vaandel.
        </p>
    </span>
}, {
    id: 2,
    name: "Eli De Frene",
    email: "eli@deltalex.be",
    image: <img className="lawyer__image" src={eliImg} alt="Eli De Frene" key="eli" />,
    Description: <span className="lawyer__description">
        <p>
            Eli startte zijn opleiding in het College van Waregem, waarna hij richting KULAK trok om zijn opleiding rechten te voltooien aan de Katholieke Universiteit Leuven.
        </p><p>
            Hierna sloot hij aan bij de Kortrijkse Balie in 2007, intussen Balie West-Vlaanderen.
        </p><p>
            Naast de vele jaren ervaring schoolde Eli zich verder bij door diverse opleidingen, waaronder in het insolventierecht, te volgen.
        </p><p>
            Zijn interesses zijn gelegen in het handels- en algemeen burgerlijk recht.
        </p><p>
            Eli is tevens actief als curator en behandelt op regelmatige basis verkeers- en verzekeringsdossiers.
        </p>
    </span>
}, {
    id: 3,
    name: "Yves Pynaert",
    email: "yves@pynaert.net",
    image: <img className="lawyer__image" src={yvesImg} alt="Yves Pynaert" key="yves" />,
    Description: <span className="lawyer__description">
        <p>
            Yves Pynaert, studeerde af aan de Vrije Universiteit Brussel.
        </p><p>
            Hij is ingeschreven aan de balie te Kortrijk sinds 23 september 1993.
        </p><p>
            Gedurende jaren verbreedde hij zijn kennis van het recht in de ruime zin.
        </p><p>
            De afgelopen periode is hij zich meer gaan toeleggen op het ondernemingsrecht, met in het bijzonder aandacht voor het insolventierecht.
        </p><p>
            In 2019 slaagde hij aan de Vives Brugge Business School in de opleiding van “Postgraduaat Vennootschapsrecht”.
        </p><p>
            Mr. Pynaert is erkend door de Vlaamse Balie als Collaboratief Advocaat om exclusief deze onderhandelingen ook aan te gaan.
        </p>
    </span>

}, {
    id: 4,
    name: "Arianne Vandecasteele",
    email: "arianne@deltalex.be",
    image: <img className="lawyer__image" src={arianneImg} alt="Arianne Vandecasteele" key="arianne" />,
    Description: <span className="lawyer__description">
        <p>
            Mr. Arianne Vandecasteele is afkomstig uit Hulste. Haar middelbare studies deed zij in ’t Fort in Kortrijk.
        </p><p>
            In 2002 studeerde ze in Gent af als licentiaat in de rechten, met onderscheiding, en won ze een prijs voor het beste eindwerk (grondige studies Internationaal recht en mensenrechten). Aan de universiteit studeerde ze ook talen, waaronder Spaans.
        </p><p>
            Op 1.10.2002 startte ze als advocaat aan de Balie van Gent, waar ze grondig, gedreven en oplossingsgericht leerde te werken.
        </p><p>
            Begin 2006 maakte ze de overstap naar de Balie van Kortrijk, waar ze haar all-round kantoor uitbouwde met focus op familierecht, aansprakelijkheden en verkeer in samenwerking met Mrs. Nicolas Duquesnoy en Patrick Clarysse. Gedurende een aantal jaren was ze ook docent recht aan het CVO Harelbeke- Avelgem.
        </p><p>
            Zij groeide op in een autosport familie, en is sinds jaren actief binnen de Nationale Autosportrechtbank van de RACB, alsook bij de regionale sportrechtbank van de VAS.
        </p><p>
            In 2009 volgde ze aan de Universiteit Gent de opleiding tot erkend bemiddelaar in Familiezaken. Sindsdien bouwde ze naast haar praktijk als advocaat ook een praktijk als bemiddelaar uit.
        </p><p>
            Sinds 2013 heeft zij ook een ruim kantoor in Stasegem, waar ze woont met haar man, en twee dochters (°2008 en ° 2010).
        </p><p>
            Diverse persoonlijke ervaringen doorheen de jaren wakkerden haar interesse in de gezondheidszorg aan. Gedurende het academiejaar 2022-2023 volgde ze aan de Universiteit Antwerpen het postgraduaat Gezondheidsrecht en Gezondheidsethiek, om zich verder in het medisch recht te specialiseren.
        </p><p>
            In 2019 werd zij vennoot binnen Deltalex Advocaten, waar de complementaire specialisaties een meerwaarde bieden voor de cliënt, gezien de toegenomen complexiteit en snelle evolutie van het recht.
        </p>
        Met meer dan 20 jaar ervaring, staat Mr. Vandecasteele voor een oprechte, persoonlijke en grondige aanpak van uw dossier.
    </span>

}, {
    id: 5,
    name: "Stijn Dewolf",
    email: "stijn@deltalex.be",
    image: <img className="lawyer__image" src={stijnImg} alt="Stijn Dewolf" key="stijn" />,
    Description: <span className="lawyer__description">
        <p>
            Mr. Stijn Dewolf genoot zijn klassieke middelbare schoolopleiding in het Heilig-Hartcollege te Waregem.
            De opleiding rechten volgde hij de eerste twee bachelorjaren aan de KULAK te Kortrijk, waarna hij in Leuven zijn opleiding voltooide aan de Katholieke Universiteit Leuven.
            Na eerst enkele jaren ervaring te hebben opgedaan in het private bedrijfsleven, koos hij in 2018 resoluut voor de advocatuur.
        </p><p>
            Deze bewuste keuze blijkt uit zijn gedrevenheid, waarbij toegankelijkheid en een persoonlijke aanpak centraal staan.
            Met de competenties die hij had verworven in het bedrijfsleven, kwam Stijn bovendien niet onbeslagen op het ijs.
        </p><p>
            Als advocaat heeft hij intussen ruime ervaring opgedaan in onder meer strafrecht, verkeersrecht, familierecht, jeugdrecht, huurrecht, aannemingsrecht, aansprakelijkheidsrecht en incasso.
            Als advocaat diepte Stijn zijn expertise in algemeen burgerlijk recht en strafrecht bovendien verder uit met de opleiding "bijzondere verhoorbijstand van verdachten" en de opleiding jeugdrecht, waarvoor hij gecertificeerd is. Op heden volgt Stijn de bijzondere opleiding cassatieprocedure in strafzaken, om u in de toekomst nóg beter van dienst te kunnen zijn.
        </p>
    </span>

}, /* {
    id: 6,
    name: "Sander",
    email: "sander@deltalex.be",
    image: <img className="lawyer__image" src={sanderImg} alt="Sander" key="sander" />,
    Description: <span className="lawyer__Description">
        Sander lorem ipsum
    </span>

}, */{
    id: 6,
    name: "Emma Van Loo",
    email: "emma@deltalex.be",
    image: <img className="lawyer__image" src={emmaImg} alt="Emma Van Loo" key="emma" />,
    Description: <span className="lawyer__description">
        <p>
            Emma Van Loo behaalde in 2022 haar rechtendiploma aan de KU Leuven en legde tijdens haar studie de focus op het privaat- en ondernemingsrecht. Tijdens haar masterjaren studeerde Emma een semester aan de Université de Tours in het kader van een Erasmusuitwisseling.
        </p><p>
            Na haar studies vervoegde Emma de balie van West-Vlaanderen, waarbij zij sinds 2023 tevens actief is als bestuurslid van de conferentie Jonge Balie.
        </p><p>
            Verder beschikt Emma over het certificaat “bijzondere verhoorbijstand van verdachten”, dit in het kader van de Salduz-wetgeving. Binnen het kantoor behartigt Emma hoofdzakelijk dossiers met betrekking tot het familierecht, burgerlijk recht in de ruime zin en verkeersrecht. U kan bij haar terecht voor zowel adviesverlening als voor bijstand bij onderhandelingen en/of gerechtelijke procedures.
        </p>
    </span>
}
];

export default lawyers;
