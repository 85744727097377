import './css/reset.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import HomePage from './pages/HomePage';
import Team from './pages/Team'
import Expertise from "./pages/Expertise";
import Contact from "./pages/Contact";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(   
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/team" element={<Team/>}/>
                <Route path="/expertise" element={<Expertise/>}/>
                <Route path="/contact" element={<Contact/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
