import React from 'react';
import HeaderBar from "../components/HeaderBar";
import "../css/contact.css";
import Footer from '../components/Footer'
import ContactMap from '../components/ContactMap';

class Contact extends React.Component {
    constructor(props) {
        window.scroll({top: 0})
        super(props);
        document.title = 'Deltalex - Contact';
    }

    componentDidMount(){
        window.addEventListener('resize', () => {this.forceUpdate()})
    }

    render() {
        return (
            <div className='contact__root--wrapper'>
                <div className="contact__header--wrapper">
                    <HeaderBar CurrentPage="contact" pageTitle={document.title} />
                </div>
                <div className='contact__content--wrapper'>
                    <div className='contact__content--text'>
                        <h1 className='contact__content--header'>Contact</h1>
                        <p>
                            Het advocatenkantoor is telefonisch bereikbaar van maandag tot vrijdag.
                        </p><p>
                            Op donderdagnamiddag werkt het kantoor achter gesloten deuren teneinde u beter van dienst te kunnen zijn.
                        </p><p>
                            Mocht u niemand kunnen bereiken zullen wij allen buiten kantoor actief zijn zodat wij u vragen in dergelijk geval terug te bellen dan wel uw vraag per mail tot ons te richten, waarop wij spoedig zullen terugkomen.
                        </p><p>
                            <strong>
                                Kortrijkse Heerweg 80, Deerlijk<br />
                                T 056 777 484<br />
                                F 056 680 689<br />
                                E: <a href='mailto:info@deltalex.be'>info@deltalex.be</a><br />
                            </strong>
                        </p><p>
                            Er is voldoende parkeerplaats voorzien in de straat waar het kantoor gelegen is.
                        </p>
                    </div>
                    <div className='contact__map--wrapper'>
                        <ContactMap/>
                    </div>
                </div>
                <div className='contact__image--wrapper'>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Contact;
